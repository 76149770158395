import { Link } from "react-router-dom";
import "./FeatureProduct.css";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS
import axios from "axios";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Swal from "sweetalert2";


function FeatureProduct({ name, rs, image, variant_id, id }) {
  const [countryCode, setCountryCode] = useState('');
  const [isWishlisted, setIsWishlisted] = useState(false); // Wishlist state

  const [product, setProduct] = useState([]);
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();

  useEffect(() => {


    detectCountry();
  }, []);
  const detectCountry = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const detectedCountry = data.country_code.toUpperCase();
      setCountryCode(detectedCountry);
      handleSubmit(detectedCountry)
    } catch (error) {
      console.error("Error detecting country:", error);
      setCountryCode('IN');
      handleSubmit('IN')
    }
  };
  const handleSubmit = async (detectedCountry) => {
    if (isAuthenticated) {
      try {
        const response = await axios.post('https://gravenautomation.com/admin/api/index', {
          user_id: auth?.uid,
          countrycode: detectedCountry
        }, {
          headers: {
            'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
          }
        });

        setProduct(response.data.wishdata.data);
        console.log(response.data.wishdata.data);
      } catch (error) {
        console.log('error---->', error);
      }
    }
  };


  useEffect(() => {
    AOS.init({
      once: false,
      //   disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  const RemoveFromWish = async (id, variant_id) => {
    try {
      const response = await axios.post('https://gravenautomation.com/admin/api/removefromwish', {
        user_id: auth?.uid,
        product_id: id,
        variant_id: variant_id,
        countrycode: countryCode
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log('response-->', response.data);

      if (response.data.status == 1) {
        Swal.fire('Success!', response.data.message, 'success');
        detectCountry();
      } else {
        Swal.fire('Oops...', response.data.message, 'error');
      }

    } catch (error) {
      console.log('error---->', error);
    }
  }

  const toggleWishlist = async (variant_id, id) => {
    if (isAuthenticated) {
      try {
        const response = await axios.post('https://gravenautomation.com/admin/api/addtowish', {
          user_id: auth?.uid,
          product_id: id,
          variant_id: variant_id,
          countrycode: countryCode
        }, {
          headers: {
            'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
          }
        });
        console.log('response-->', response.data);

        if (response.data.status == 1) {
          Swal.fire('Success!', response.data.message, 'success');
          detectCountry();
        } else {
          RemoveFromWish(id, variant_id)

        }

      } catch (error) {
        console.log('error---->', error);
      }
    }
    else {
      Swal.fire('Oops...', 'Please log in to add items to your cart.', 'error');
    }
  };

  return (
    <div data-aos="fade-up"
      data-aos-duration="3000" className="col">
      <div className="card feature-card" style={{ width: '100%', margin: 0 }}> 
        <div
        className="wishlist-icon"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
          fontSize: "1.5rem",
          color: product.some(item => item.product_id === id) ? "#ff4343" : "gray",
        }}
        onClick={() => toggleWishlist(variant_id, id)}
      >
        <i className={product.some(item => item.product_id === id) ? "fas fa-heart" : "far fa-heart"}></i>
      </div>
        <img
          className="card-img-top"
          // height=""
          // style={{width:'90%',alignSelf:'center',height:'18vh'}}
          alt={`Image of ${name}`}
          src={`https://gravenautomation.com/admin/public/product/images/${image}`}
        />
        <div className="card-body">
          <h5 className="card-title text-center text-truncate">{name}</h5>
          <p className="card-text text-center text-muted">{rs}</p>
          <div className="d-grid gap-2">
            <Link to={`/${countryCode}/products/${variant_id}/${id}`} className="btn btn-outline-dark custom-btn">
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;
