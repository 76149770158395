import React, { useEffect, useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { useHistory, Link } from 'react-router-dom';
import './Login.css'; // Import the CSS file
import axios from 'axios';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const signIn = useSignIn();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const [message, setMessage] = useState(''); // To display error/success messages
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        const detectCountry = async () => {
            try {
                // Fetch data from ipapi.co
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                const detectedCountry = data.country_code.toUpperCase(); // Get country code and convert to lowercase
                // alert(detectedCountry);

                setCountryCode(detectedCountry);
                if (isAuthenticated) {
                    history.push(`/${detectedCountry}/index`);
        
                }
            } catch (error) {
                console.error("Error detecting country:", error);
                setCountryCode('IN'); // Fallback to a default country (USA)
            } finally {
                // setLoading(false); // Stop loading when the country is detected
            }
        };

        detectCountry();
    }, []);
  

    // useEffect(() => {
    //     getCheck();
    // }, [isAuthenticated, history]);


    const handleLogin = async (e) => {
        e.preventDefault();

     
        // Fetch API call
        try {
            const response = await axios.post('https://gravenautomation.com/admin/api/login', {

                email: email,
                password: password

            }, {
                headers: {
                    'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
                }
            });
            console.log('response.data.status',response.data);

            // setData(response.data);
            if (response.data.status == 1) {
                // If login is successful, store token and user details
                if (
                    signIn({
                        auth: {
                            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZXhwIjo4MDA4NjA1MTk1fQ.ijw603AjpAqNwnUXmv6YB5L6m5aL-llIgBsTJo-k2r8',
                        },

                        // expiresIn: 1, // Set expiration time in seconds
                        tokenType: "Bearer", // Set the token type
                        userState: { name: response.data.userdata.user_name, uid: response.data.userdata.user_id, user_mail: response.data.userdata.user_mail, user_phone: response.data.userdata.user_phone },
                    })
                    
                ) {
                    const now = new Date().getTime(); // Current time
                    const expiryTime = now + 60 * 60 * 1000; // Set expiration time to 1 hour
                    localStorage.setItem('expiryTime', expiryTime);
                    // Redirect on successful login
                    history.push(`/${countryCode}/index`);
                } else {
                    setMessage("Login failed. Try again.");
                }
            } else {
                setMessage("Invalid login credentials. Please check your email and password.");
            }
        } catch (error) {
            console.log(error);
            // setError(error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">

                <img src="/logo1.png" alt="Logo" className="logo" />
                <h2>Login</h2>
                <form onSubmit={handleLogin}>


                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="input-field"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="input-field"
                        required
                    />
                    <button type="submit" className="login-button">Login</button>
                </form>
                {/* Display message if present */}
                {message && <p className="message">{message}</p>}
                <p className="signup-link mt-3">
                    Don't have an account? <Link to={`/${countryCode}/signup`}><a>Sign Up</a></Link>
                </p>

            </div>
        </div>
    );
};

export default Login;
