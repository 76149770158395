// Categories.js
import React, { useEffect, useState } from 'react';
import './categories.css'; // The updated CSS for overlay effect
import Banner from '../landing/Banner';
import { Link } from "react-router-dom";




const Categories = () => {
    const [countryCode, setCountryCode] = useState('');
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch('https://automation.sai247.in/api/Allcategory/Machine');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const json = await response.json();
            console.log('json', json);

            setData(json.data);
        } catch (err) {
            // setError(err);
        } finally {

        }
    };
    
    useEffect(() => {
        const detectCountry = async () => {
            try {
                // Fetch the user's country information from ipapi.co
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                const detectedCountry = data.country_code.toUpperCase(); // Convert country code to uppercase
                setCountryCode(detectedCountry); // Set the detected country code
            } catch (error) {

                setCountryCode('IN'); // Fallback to default country (e.g., India)
            }
        };

        detectCountry(); // Run the function on component mount
        fetchData(); // Run the function on component mount
    }, []);
   
    return (
        <div>
            <Banner
                heading={'Empowering Industry Innovations'}
                pera={'Leading the way in industrial automation solutions, delivering unparalleled excellence through top-tier products and exceptional service.'}
            />
               <section  >
                <div className="products-section" >
                    <h2 style={{ textAlign: 'center', fontSize: 25, }} className="section-title">Products We Deal In</h2>
                    <div style={{ backgroundColor: '#fff', marginTop: 0 }} className="card-container container">
                        <div className='row'  >
                            {data.map((card, index) => (
                                <Link
                                    key={index}
                                    to={`/${countryCode}/category/${card.section_slug}`}

                                    style={{ textDecoration: 'none', marginTop: 10, paddingLeft: 0, paddingRight: 0 }} // Optionally, remove the default link underline
                                    className='col-md-4 col-sm-6 col-lg-3 coloms'
                                >
                                    <div
                                        data-aos="fade-up"
                                        data-aos-duration="3000"
                                        style={{ backgroundColor: '#fff', cursor: 'pointer' }}
                                        className="card2"
                                    >
                                        <img
                                            style={{ backgroundColor: '#fff' }}
                                            src={`https://automation.sai247.in/public/images/sections/category/${card.section_img}`}
                                            alt={'title'}
                                            className="card2-image"
                                        />
                                        <h2 style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }} className="card2-title">{card.section_name}</h2>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Categories;
