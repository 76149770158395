import ProductDetail from "./products/detail/ProductDetail";
import { Switch, Route, Redirect, BrowserRouter as Router, useLocation } from "react-router-dom";
import Landing from "./landing/Landing";
import Contact from "./contact/Contact";
import About from "./about/About";
import HomeIndex from "./mainscreen/Index";
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import Login from "./Login/Login";
import { useState, useEffect } from "react";
import MyCart from "./mycart/MyCart";
import './App.css';
import Blog from "./blog/Blog";
import Profile from "./myprofile/Profile";
import Signup from "./Login/Signup";
import Header from "./template/Header";  
import Footer from "./template/Footer";  
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import LandingCategorywise from "./projectCategoryWise/LandingCategorywise";
import LandingIndustrywise from "./projectIndustryWise/LandingIndustrywise";
import Industries from "./Industries/Industries";
import Mission from "./Mission/Mission";
import ShippingDelivery from "./ShippingDelivery/ShippingDelivery";
import Newandupdate from "./newandupdate/Newandupdate";
import Privacypolicy from "./privacypolicy/Privacypolicy";
import Help_Center_FAQ from "./help_center_faq/Help_Center_FAQ";
import Warranty_Information from "./warranty_information/Warranty_Information";
import Returns_Exchanges from "./Returns_Exchanges/Returns_Exchanges";
import Terms_and_Conditions from "./Terms&Conditions/Terms_and_Conditions";
import Terms_of_Service from "./Terms_of_Service/Terms_of_Service";
import Cookie_Policy from "./Cookie_Policy/Cookie_Policy";
import Categories from "./categories/Categories";
import Wishlist from "./wishlist/Wishlist";

function App() {
  // Initialize authentication store
  const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });

  const [countryCode, setCountryCode] = useState('');
  const location = useLocation();

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase();
        setCountryCode(detectedCountry);
      } catch (error) {
        // setCountryCode('UN'); // Fallback to India if API fails
      }
    };
    detectCountry();
  }, []);

  // Redirect logic to prevent manually changing the URL country code
  useEffect(() => {
    if (countryCode) {
      const currentPathCountry = location.pathname.split('/')[1]; // Get the country from the current URL
      if (currentPathCountry && currentPathCountry !== countryCode) {
        window.location.href = `/${countryCode}${location.pathname.substring(currentPathCountry.length + 1)}`;
      }
    }
  }, [countryCode, location.pathname]);

  const hideHeaderFooterRoutes = ["/login", "/signup"];
  const showHeaderFooter = !hideHeaderFooterRoutes.some(route => location.pathname.includes(route));

  if (!countryCode) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <AuthProvider store={store}>
      <Router>
        {showHeaderFooter && <Header countryCode={countryCode} />}
        <Switch>
          <Route path="/" exact>
            <Redirect to={`/${countryCode}/index`} />
          </Route>

          <Route path="/:country/index" exact>
            <HomeIndex />
          </Route>
          <Route path="/:country/Blog" exact>
            <Blog />
          </Route>
          <Route path="/:country/products" exact>
            <Landing />
          </Route>
          <Route path="/:country/categories" exact>
            <Categories />
          </Route>
          <Route path="/:country/Industries" exact>
            <Industries />
          </Route>
          <Route path="/:countryCode/products/:variant_id/:productId" exact>
            <ProductDetail />
          </Route>
          <Route path="/:countryCode/category/:categoryId" exact>
            <LandingCategorywise />
          </Route>
          <Route path="/:countryCode/Industry/:IndustryID" exact>
            <LandingIndustrywise />
          </Route>
          <Route path="/:countryCode/products/:variant_id/:productId/checkoutPage" exact>
            <CheckoutPage />
          </Route>
          <Route path="/:country/contact" exact>
            <Contact />
          </Route>
          <Route path="/:country/Mission" exact>
            <Mission />
          </Route>
          <Route path="/:country/Shipping-Delivery" exact>
            <ShippingDelivery />
          </Route>
          <Route path="/:country/newandupdate" exact>
            <Newandupdate />
          </Route>
          <Route path="/:country/Privacy-policy" exact>
            <Privacypolicy />
          </Route>
          <Route path="/:country/help-center/FAQ" exact>
            <Help_Center_FAQ />
          </Route>
          <Route path="/:country/warranty-information" exact>
            <Warranty_Information />
          </Route>
          <Route path="/:country/returns-exchanges" exact>
            <Returns_Exchanges />
          </Route>
          <Route path="/:country/terms-and-Conditions" exact>
            <Terms_and_Conditions />
          </Route>
          <Route path="/:country/terms-of-service" exact>
            <Terms_of_Service />
          </Route>
          <Route path="/:country/cookie-policy" exact>
            <Cookie_Policy />
          </Route>
          <Route path="/:country/Profile" exact>
            <Profile />
          </Route>
          <Route path="/:country/about" exact>
            <About />
          </Route>
          <Route path="/:country/login" exact>
            <Login />
          </Route>
          <Route path="/:country/signup" exact>
            <Signup />
          </Route>
          <Route path="/:country/cart" exact>
            <MyCart />
          </Route>
          <Route path="/:country/wishlist" exact>
            <Wishlist />
          </Route>

          <Route path="*">
            <Redirect to={`/${countryCode}/index`} />
          </Route>
        </Switch>
        {showHeaderFooter && <Footer />}
      </Router>
    </AuthProvider>
  );
}

export default App;
