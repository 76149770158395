import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

function Footer() {
  const [countryCode, setCountryCode] = useState('');
  const style = {
    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    input: {
      width: '70%',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '1rem',
      marginRight: '10px',
      marginBottom: '10px',
      flexGrow: 1,
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1rem',
      marginBottom: '10px',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    responsiveInput: {
      width: '100%',
      marginRight: 0,
    },
  };

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase();
        setCountryCode(detectedCountry);
      } catch (error) {
        console.error("Error detecting country:", error);
        setCountryCode('IN');
      }
    };
    detectCountry();
  }, []);

  const location = useLocation();

  if (location.pathname === `/${countryCode}/login` || location.pathname === `/${countryCode}/signup`) {
    return null;
  }

  return (
    <footer className="footer mt-1 py-4 bg-dark text-light">
      <div className="container-fluid">
        <div className="d-flex justify-content-between flex-wrap">
          {/* Company Information Section */}
          <div className="footer-section text-center text-md-left">
            <h5 className="text-uppercase mb-3">Company Information</h5>
            <ul className="list-unstyled">
              <li><Link to={`/${countryCode}/about`} className="footer-link">About Us</Link></li>
              <li><Link to={`/${countryCode}/Mission`} className="footer-link">Mission & Values</Link></li>
              {/* <li><Link to={`/${countryCode}/careers`} className="footer-link">Careers</Link></li> */}
              <li><Link to={`/${countryCode}/Newandupdate`} className="footer-link">News & Updates</Link></li>
              <li><Link to={`/${countryCode}/contact`} className="footer-link">Contact Us</Link></li>
            </ul>
          </div>

          {/* Customer Support Section */}
          <div className="footer-section text-center">
            <h5 className="text-uppercase mb-3">Customer Support</h5>
            <ul className="list-unstyled">
              <li><Link to={`/${countryCode}/help-center/FAQ`} className="footer-link">Help Center / FAQ</Link></li>
              <li><Link to={`/${countryCode}/warranty-information`} className="footer-link">Warranty Information</Link></li>
              <li><Link to={`/${countryCode}/Shipping-Delivery`} className="footer-link">Shipping & Delivery</Link></li>
              <li><Link to={`/${countryCode}/returns-exchanges`} className="footer-link">Returns & Exchanges</Link></li>
              <li><Link to={`/${countryCode}/terms-and-Conditions`} className="footer-link">Terms & Conditions</Link></li>
              {/* <li><Link to={`/${countryCode}/Privacy-policy`} className="footer-link">Privacy Policy</Link></li> */}
            </ul>
          </div>

          {/* Products Section */}
          <div className="footer-section text-center">
            <h5 className="text-uppercase mb-3">Products</h5>
            <ul className="list-unstyled">
              {/* <li> <a href="#Category" className="footer-link">Product Categories</a></li> */}
              <li><Link to={`/${countryCode}/products`} className="footer-link">All Products</Link></li>
              <li><Link to={`/${countryCode}/categories`} className="footer-link">Product Categories</Link></li>
              <li><Link to={`/${countryCode}/Industries`} className="footer-link">Industries</Link></li>
              {/* <li className="footer-link">Special Offers</li> */}
            </ul>
          </div>

          {/* Legal Section */}
          <div className="footer-section text-center">
            <h5 className="text-uppercase mb-3">Legal</h5>
            <ul className="list-unstyled">
              <li><Link to={`/${countryCode}/terms-of-service`} className="footer-link">Terms of Service</Link></li>
              <li><Link to={`/${countryCode}/Privacy-policy`} className="footer-link">Privacy Policy</Link></li>
              <li><Link to={`/${countryCode}/cookie-policy`} className="footer-link">Cookie Policy</Link></li>
            </ul>
          </div>

          {/* Social Media & Newsletter Section */}
          <div className="footer-section text-center text-md-left">
            <h5 className="text-uppercase mb-3">Connect with Us</h5>
            <div className="d-flex justify-content-center">
              <a href="https://www.facebook.com/" className="mx-3 footer-social-icon" aria-label="Facebook">
                <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
              </a>
              <a href="https://www.instagram.com/" className="mx-3 footer-social-icon" aria-label="Instagram">
                <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
              </a>
              <a href="https://twitter.com/" className="mx-3 footer-social-icon" aria-label="Twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
              </a>
            </div>
            <form className="newsletter-form mt-3" style={style.form}>
              <input
                type="email"
                placeholder="Enter your email"
                className="newsletter-input"
                style={style.input}
              />
              <button type="submit" className="newsletter-button" style={style.button}>
                Subscribe
              </button>
            </form>
          </div>

          {/* Contact Information Section */}
          {/* <div className="footer-section text-center text-md-left">
            <h5 className="text-uppercase mb-3">Contact Information</h5>
            <p style={{ fontSize: "0.9rem", color: '#fff' }}>
              Phone: <a href="tel:+917316980864" className="footer-link">+91-7316980864</a><br />
              Email: <a href="mailto:info@gravenautomation.com" className="footer-link">info@gravenautomation.com</a><br />
              Address: F38, Ground Floor Gandhi Vihar, New Delhi, India - 110009
            </p>
          </div> */}
        </div>

        {/* Copyright Section */}
        <div className="row">
          <div className="col text-center">
            <p className="mb-1" style={{ fontSize: "0.9rem" }}>
              Phone: <a href="tel:+917316980864" className="text-white">+91-7316980864</a> | 
              Email: <a href="mailto:info@gravenautomation.com" className="text-white">info@gravenautomation.com</a>
            </p>
            <p className="mb-0" style={{ fontSize: "0.9rem" }}>
              Address: F38, Ground Floor Gandhi Vihar, New Delhi, India - 110009
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className="row">
          <div className="col text-center">
            <p className="mt-3 mb-0" style={{ fontSize: "0.85rem", color: "#999" }}>
              &copy; 2024 Graven Automation PVT LTD. All rights reserved.
            </p>
          </div>
        </div>
      </div>


      {/* Custom Styles */}
      <style jsx>{`
        .footer {
          background-color: #222;
          color: #fff;
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        .footer h5 {
          font-size: 1.1rem;
          font-weight: 600;
          color: #fff;
        }

        .footer-link {
          color: #ccc;
          font-size: 0.9rem;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .footer-link:hover {
          color: #fff;
          text-decoration: underline;
        }

        .footer-social-icon {
          color: #ccc;
          transition: color 0.3s ease, transform 0.3s ease;
        }

        .footer-social-icon:hover {
          color: #fff;
          transform: scale(1.1);
        }

        .footer-section {
          flex-basis: 15%;
          margin-bottom: 1rem;
        }

        @media (max-width: 768px) {
          .footer-section {
            flex-basis: 50%;
            margin-bottom: 1.5rem;
          }
        }

        @media (max-width: 576px) {
          .footer-section {
            flex-basis: 100%;
            margin-bottom: 1.5rem;
          }
        }

        @media (max-width: 576px) {
          .footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          .footer h5 {
            font-size: 1rem;
          }

          .footer-link {
            font-size: 0.85rem;
          }

          .footer-social-icon {
            font-size: 1.5rem;
          }
        }

        .newsletter-form {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .newsletter-input {
          flex-grow: 1;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-right: 10px;
          font-size: 1rem;
          width: 70%;
        }

        .newsletter-button {
          padding: 10px 20px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
        }

        .newsletter-button:hover {
          background-color: #0056b3;
        }

        .newsletter-input:focus {
          border-color: #007bff;
          outline: none;
        }

        @media (max-width: 576px) {
          .newsletter-input {
            margin-right: 0;
            width: 100%;
          }
        }

        .footer p {
          font-size: 0.85rem;
        }
      `}</style>
    </footer>
  );
}

export default Footer;
