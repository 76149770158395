import React, { useEffect, useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { useHistory, Link } from 'react-router-dom';
import './Login.css'; // Import the CSS file
import axios from 'axios';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const signIn = useSignIn();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const [message, setMessage] = useState(''); // To display error/success messages
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        const detectCountry = async () => {
            try {
                // Fetch data from ipapi.co
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                const detectedCountry = data.country_code.toUpperCase(); // Get country code and convert to lowercase
                // alert(detectedCountry);

                setCountryCode(detectedCountry);
                if (isAuthenticated) {
                    history.push(`/${detectedCountry}/index`); // Redirect if already authenticated
                }
            } catch (error) {
                console.error("Error detecting country:", error);
                setCountryCode('IN'); // Fallback to a default country (USA)
            } finally {
                // setLoading(false); // Stop loading when the country is detected
            }
        };

        detectCountry();
    }, []);
    const getCheck = () => {
  
        if (isAuthenticated) {
            history.push(`/${countryCode}/index`); // Redirect if already authenticated
        }
    };

    // useEffect(() => {
    //     getCheck();
    // }, [isAuthenticated, history]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const body = {
            name:name,
                    email:email,
                    phone:phone,
                    password:password
        }
        console.log(body);

        try {
            const response = await axios.post('https://gravenautomation.com/admin/api/registration', {

                
                    name:name,
                    email:email,
                    phone:phone,
                    password:password
                  

            }, {
                headers: {
                    'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
                }
            });
            console.log(response.data.message);

            // setData(response.data);
            if (response.data.message == 'User successfully registered') {
                // If login is successful, store token and user details
                if (
                    signIn({
                        auth: {
                            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZXhwIjo4MDA4NjA1MTk1fQ.ijw603AjpAqNwnUXmv6YB5L6m5aL-llIgBsTJo-k2r8',
                        },

                        expiresIn: 2000, // Set expiration time in seconds
                        tokenType: "Bearer", // Set the token type
                        userState: { name: response.data.user.name, uid: response.data.user.id, user_mail: response.data.user.email, user_phone:  response.data.user.phone },
                    })
                ) {
                    // Redirect on successful login
                    history.push(`/${countryCode}/index`);
                } else {
                    setMessage("Login failed. Try again.");
                }
            } else {
                setMessage("Invalid login credentials. Please check your email and password.");
            }
        } catch (error) {
            console.log(error);
            // setError(error.message);
        }
    };

    return (
        <div className="login-container mt-5">
            <div className="login-form">

                <img src="/logo1.png" alt="Logo" className="logo" />
                <h2>Sign up</h2>
                <form onSubmit={handleLogin}>


                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="name"
                        className="input-field"
                        required
                    />
                    <input
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="phone"
                        className="input-field"
                        required
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="input-field"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="input-field"
                        required
                    />
                    <button type="submit" className="login-button">Sign up</button>
                </form>
                {/* Display message if present */}
                {message && <p className="message">{message}</p>}
                <p className="signup-link mt-3">
                    <a> Already have an account? <Link to={`/${countryCode}/login`}>Login</Link></a>
                </p>

            </div>
        </div>
    );
};

export default Signup;
